import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Cheatsheets`}</h1>
    <p>{`Everyday commands, tips and hints I use.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="bash" mdxType="Link">1. Bash</Link>
      </li>
      <li parentName="ul">
        <Link to="yarn" mdxType="Link">2. Yarn</Link>
      </li>
      <li parentName="ul">
        <Link to="npm" mdxType="Link">3. Npm</Link>
      </li>
      <li parentName="ul">
        <Link to="expo" mdxType="Link">4. Expo</Link>
      </li>
      <li parentName="ul">
        <Link to="vscode" mdxType="Link">5. Visual Studio Code</Link>
      </li>
      <li parentName="ul">
        <Link to="linux" mdxType="Link">6. Linux</Link>
      </li>
      <li parentName="ul">
        <Link to="git" mdxType="Link">7. Git</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      